
// @TODO : export this to a global configuration
const languagesFallBack = ['fr', 'en']


/// !!! Don't use directly this function, use `getStringValues` instead to manage more cases.
export default function getLocalizedLiterals(propertyLiterals) {

  if (!propertyLiterals || !propertyLiterals.length) return []

  const langStrings = recursiveLangExtract(propertyLiterals, 0)
  return langStrings
}



function recursiveLangExtract(propertyLiterals, langIndex) {

  if (langIndex > propertyLiterals.length) {
    console.warn('No lang literal value found for this fallback languages', languagesFallBack)
    console.warn('Content of the sourceLiterals is', propertyLiterals)
    return []
  }

  const lang = languagesFallBack[langIndex]

  const langStrings = propertyLiterals.reduce((acc, literal) => {
    if (literal._language === lang) acc.push(literal._value) // gatsby-mode
    if(literal['@language'] === lang) acc.push(literal['@value']) // jsonld-mode
    if(literal.language === lang) acc.push(literal.value) // rdfx-graphql-mode
    return acc
  }, [])

  // check on length = 0 for propertyLiterals done before
  if (!langStrings.length) return recursiveLangExtract(propertyLiterals, langIndex + 1)

  return langStrings
}
