import React from 'react'

const AppConfigContext = React.createContext({
  menu : {
    open : true, 
    handleOpen : () => {},
    handleClose: () => {},
  },
})

export default AppConfigContext

// @TODO: see if we can init the context value here ?

export function AppConfigContext2(){

  const [open, setOpen] = React.useState(true)
  const handleOpen = () => { setOpen(true) }
  const handleClose = () => { setOpen(false) }


  return React.createContext({
    menu : {
      open, 
      handleOpen,
      handleClose,
    },
  })
}