import React from 'react'
import { Link } from 'gatsby-theme-material-ui'
import { useTheme } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Copyright from './Copyright'
import styled from '@emotion/styled'
import AppConfigContext from '../../reactContexts/AppConfigContext'
import MultiOntologiesMenu from '../dashorm/menuHelpers/MultiOntologiesMenu.js'

import LogoImg from "../../../logos-data/mindMatcherOneLine.png"
import DialogDev from './dialogDev'

// source of inspiration: https://mui.com/components/drawers/#persistent-drawer

let drawerWidth = 350 //default size @TODO: see how to make clean with prop

// @TODO: put them all in a "canvasHelper" export file
//      : the problem to solve is the "dynamic" `drawerWidth` value
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
    // width: { sm: `calc(100% - ${drawerWidth}px)` }
  }),
);

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  // width: { sm: `calc(100% - ${drawerWidth}px)` },
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  // color: theme.palette.primary.contrastText,
  // backgroundColor: theme.palette.primary.main,
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

// @TODO: end to export

export default function DashLayout(props) {

  const theme = useTheme()
  const { menuWidth = 350 } = props

  drawerWidth = menuWidth //@TODO: clean this for better intégration in styled via props

  // Init the AppConfigContext
  const [open, setOpen] = React.useState(true)
  const handleOpen = () => { setOpen(true) }
  const handleClose = () => { setOpen(false) }
  const [appConfig] = React.useState({menu : {
    open, 
    handleOpen,
    handleClose,
  },})
  

  const {
    // simple / 1 ontology version
    // DashMenuComponent_old = (props) => (<DefaultMenu {...props} />),
    // multi-ontology version
    DashMenuComponent = (props) => (<MultiOntologiesMenu {...props} />)
  } = props

  // const backColor = { 'backgroundColor': theme.palette.primary.main }
  const redColor = { color: theme.palette.primary.main }
  const headerBackground = { backgroundColor: theme.palette.secondary.main }

  const headerLinkSx = {
    textDecoration: 'none',
    '&:hover' : {
      textDecoration: 'underline'
    }
  }
  return (
    <>
    <AppConfigContext.Provider value= {appConfig}>
      <AppBarStyled position='sticky' open={open} sx={headerBackground}>
        <Toolbar >
          <IconButton
            aria-label="open drawer"
            onClick={handleOpen}
            edge="start"
            sx={{ mr: 1, ...(open && { display: 'none' }) }}
            anchor="left"
          >
            <MenuIcon />
          </IconButton>
          <Box component="div" display="inline-block" anchor="left" >
            <img src={LogoImg} alt="Logo Mindmatcher" />
          </Box>
          <Typography component="h1" variant="h6" noWrap style={{ margin: "auto" }}>
            <Link to='/' sx={{ ...headerLinkSx , ...redColor}} >Explorateur d'ontologies</Link>
          </Typography>
          <DialogDev />
          {/* @TODO: integrate search with :
            * logo integration: https://mui.com/components/app-bar/#prominent
            * or search text integration: https://mui.com/components/app-bar/#app-bar-with-search-field
          */}
        </Toolbar>
      </AppBarStyled>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <DashMenuComponent {...props} />

      </Drawer>
      <Main open={open}>
        <Container disableGutters maxWidth={false}>
          {props.children}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </Main>
    </AppConfigContext.Provider>
    </>
  )

}
